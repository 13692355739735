export const DEFAULT_WIDGET_CONFIG = {
  'data-businessunit-id': '',
  'data-locale': '',
  'data-style-height': '75px',
  'data-style-width': '150px',
  'data-template-id': '',
  'data-theme': 'light',
}

declare global {
  interface Window {
    Trustpilot?: {
      loadFromElement: (elt: HTMLElement, forceReload?: boolean) => void
    }
  }
}
