import type { BuybackOffer, Currency } from '@backmarket/http-api'
import type {
  Swap,
  SwapAd,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { defineStore } from 'pinia'

interface State {
  advertisement: SwapAd | null
  offer: BuybackOffer | null
  swapCurrency: Currency | null
}

export const useSwapStore = defineStore('swapStore', {
  state: (): State => ({
    advertisement: null,
    offer: null,
    swapCurrency: useMarketplace().market.defaultCurrency,
  }),
  getters: {
    hasOffer: (state): boolean => !isEmpty(state.offer),
    hasAdvertisement: (state): boolean =>
      !isEmpty(state.advertisement) && isEmpty(state.offer),
  },
  actions: {
    resetSwap() {
      this.advertisement = null
      this.offer = null
    },
    setSwap(swap: Swap) {
      this.offer = swap?.offer
      this.advertisement = swap?.ad
    },
  },
})
