import { useScriptTag } from '#imports'
import { type Ref, onMounted, ref } from 'vue'

import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { toRfc4646Locale } from '@backmarket/utils/string/toRfc4646Locale'

import type { Features } from '~/features'

import { useTrustmark } from '../useTrustmark'

import { DEFAULT_WIDGET_CONFIG } from './TrustpilotBadge.config'

function useLoadTrustpilotLibrary(
  trustpilotWidgetRef: Ref<HTMLElement | undefined>,
) {
  const logger = useLogger()
  const loaded = ref(false)

  const url = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'

  const { load } = useScriptTag(
    url,
    () => {
      if (!trustpilotWidgetRef.value) {
        logger.error(
          '[Trustpilot] Failed to load widget. Reference to the widget element is undefined',
        )

        return
      }

      window.Trustpilot?.loadFromElement(trustpilotWidgetRef.value)
    },
    { immediate: false },
  )

  onMounted(() => {
    load()
      .catch(() => {
        logger.error('[Trustpilot] Failed to load script')
      })
      .finally(() => {
        loaded.value = true
      })
  })

  return loaded
}

export const TRUSTPILOT_BADGE_TEMPLATE_ID = '53aa8807dec7e10d38f59f32'

function useTrustpilotConfig() {
  const logger = useLogger()
  const { market } = useMarketplace()
  const { withTrustpilot, trustmarkConfig } = useTrustmark()

  if (
    !withTrustpilot ||
    isEmpty(trustmarkConfig) ||
    !('businessUnitId' in trustmarkConfig && 'websiteHref' in trustmarkConfig)
  ) {
    logger.error(
      '[Truspilot] Missing businessUnitId and/or websiteHref in trustmarkConfig',
    )

    return undefined
  }

  const locale = toRfc4646Locale(market.defaultLocale)

  const configuration = {
    ...DEFAULT_WIDGET_CONFIG,
    'data-businessunit-id': trustmarkConfig.businessUnitId,
    'data-template-id': TRUSTPILOT_BADGE_TEMPLATE_ID,
    'data-locale': locale,
    href: trustmarkConfig.websiteHref,
  }

  return configuration
}

export function useTrustpilot(
  trustpilotWidgetRef: Ref<HTMLElement | undefined>,
) {
  const configuration = useTrustpilotConfig()
  const isLoaded = useLoadTrustpilotLibrary(trustpilotWidgetRef)

  return {
    configuration,
    isLoaded,
  }
}

export function useTrustpilotCarousel(
  widgetContainerRef: Ref<HTMLElement | undefined>,
) {
  const locale = useI18nLocale()
  const logger = useLogger()
  const { features } = useMarketplace<Features>()

  if (!features.trustpilotConfig.businessUnitId) {
    logger.error('[Trustpilot] Missing trustpilot configuration')

    return {
      isLoaded: false,
      configuration: undefined,
    }
  }
  const lang = locale.split('-')[0]

  const TRUSTPILOT_CAROUSEL_TEMPLATE_ID = '53aa8912dec7e10d38f59f36'

  const configuration = {
    'data-businessunit-id': features.trustpilotConfig.businessUnitId,
    'data-template-id': TRUSTPILOT_CAROUSEL_TEMPLATE_ID,
    'data-locale': locale,
    'data-review-languages': lang,
    'data-font-family': 'Noto Sans',
    'data-stars': '4,5',
    'data-style-width': '100%',
    'data-text-color': '#0F1117',
    'data-theme': 'light',
    href: features.trustpilotConfig.websiteHref,
    class: 'trustpilot-widget',
  }

  const isLoaded = useLoadTrustpilotLibrary(widgetContainerRef)

  return { configuration, isLoaded }
}
